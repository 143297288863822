<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <p class="p-title">基础信息</p>
            <el-form ref="form" v-bind:model="form" label-width="110px" :rules="rules" class="dialog_box" label-position="left">
                <el-form-item label="活动ID：" prop="resource_id">
                    <el-input class="form-input" v-model="form.resource_id" placeholder="请输入活动id" style="margin-bottom:10px;"></el-input>
                </el-form-item>
                <el-form-item label="活动名称：" prop="name">
                    <el-input class="form-input" v-model="form.name" placeholder="请输入标题/描述" style="margin-bottom:10px;"></el-input>
                </el-form-item>
                <el-form-item label="起止时间:" prop="time">
                    <el-date-picker ref="drawDate" :picker-options="pickerOptions" style="margin-bottom:10px" v-model="form.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="满足类型:" prop="ty">
                    <el-radio-group v-model="form.ty">
                        <el-radio :label="1">满金额</el-radio>
                        <el-radio :label="2">满件数</el-radio>
                    </el-radio-group>

                </el-form-item>
                <el-form-item prop="price" label="使用条件:">
                    <div class="price">
                        <el-input class="form-input" v-model="form.price" placeholder="请输入条件值"></el-input>
                        <span v-if="form.ty==1" style="margin-left: 5px;">元</span>
                        <span v-if="form.ty==2" style="margin-left: 5px;">件</span>
                    </div>
                </el-form-item>
                <el-form-item label="活动关联:" prop="resource">
                    <el-radio-group v-model="form.resource">
                        <el-radio :label="0">全场</el-radio>
                        <el-radio :label="1">品牌</el-radio>
                        <el-radio :label="3">专场</el-radio>
                        <el-radio :label="4">套装购</el-radio>
                        <el-radio :label="5">拼团</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态:" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="赠送优惠券：" prop="discountList">
                    <el-button type="primary" @click="addDiscount" style="margin-bottom:10px">选择优惠券</el-button>
                    <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="form.discountList" style="width: 90%">
                        <el-table-column prop="id" label="id" width="50" align="center"></el-table-column>
                        <el-table-column prop="name" label="优惠券名称" width="150" align="center"></el-table-column>
                        <el-table-column prop="price" label="优惠劵价格" width="100" align="center"></el-table-column>
                        <el-table-column prop="ctime" label="优惠券开始时间" width="180" align="center"></el-table-column>
                        <el-table-column prop="etime" label="优惠券结束时间" width="180" align="center"></el-table-column>
                        <el-table-column prop="full_price" label="满足金额" align="center"></el-table-column>
                        <el-table-column prop="is_receive" label="是否可领取" width="150" align="center">
                            <template slot-scope="scope">
                                <el-tag type="primary" v-if="scope.row.is_receive === 2">可领取</el-tag>
                                <el-tag type="info" v-if="scope.row.is_receive === 1">不可领取</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="限制领取" prop="limit" align="center" width="90"></el-table-column>
                        <el-table-column prop="resource" label="优惠券类型" align="center" width="120">
                            <template slot-scope="scope">
                                <el-tag type="primary" v-if="scope.row.resource === 0">全场</el-tag>
                                <el-tag type="primary" v-if="scope.row.resource === 1">品牌</el-tag>
                                <el-tag type="primary" v-if="scope.row.resource === 3">专场</el-tag>
                                <el-tag type="primary" v-if="scope.row.resource === 4">套装购</el-tag>
                                <el-tag type="primary" v-if="scope.row.resource === 5">拼团</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ty" label="折扣类型" width="100" align="center">
                            <template slot-scope="scope">
                                <el-tag type="primary" v-if="scope.row.ty === 1">直减</el-tag>
                                <el-tag type="primary" v-if="scope.row.ty === 2">折扣</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="resource_id" label="类型id" align="center"></el-table-column>
                        <el-table-column label="状态" prop="state" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="stock" label="库存" width="100" align="center"></el-table-column>
                        <el-table-column label="操作" align="center" width="170" fixed="right">
                            <template slot-scope="scope">
                                <el-button type="text" icon="el-icon-edit" @click="deleteDiscount(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <!-- <Pagination
                        v-bind:pageInfo="pageInfo"
                        v-on:refreshPageSize="refreshPageSize"
                        v-on:refreshPageNum="refreshPageNum"
                    />-->
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="saveData">保存</el-button>
                </el-form-item>
            </el-form>

            <Discountcom :addVisible="addVisible" :title="title" @toggleAdd="toggleAdd" @getAddGood="getAddGood" :pGoodsList="form.discountList">
            </Discountcom>
        </div>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import Breadcrumb from '@/components/Breadcrumb';
import Discountcom from '@/components/Discountcom';
import bus from '@/common/bus';
import {
    request
} from '@/common/request';
import {
    deal
} from '@/common/main';

let loads = new loadEvents();
export default {
    name: 'ArticleAdd',
    components: {
        Breadcrumb,
        Discountcom,
        Pagination
    },
    data() {
        return {
            pageInfo: {},
            menuList: ['营销', '满送劵新增'],
            loading: false,
            title: "选择商品",
            queryParams: {
                page: 1,
                pagesize: 10
            },
            //验证规则
            rules: {
                name: [{
                    required: true,
                    message: '请输入活动名称',
                    trigger: 'blur'
                }],

                time: [{
                    required: true,
                    message: '请选择起止时间',
                    trigger: 'blur'
                }],
                ty: [{
                    required: true,
                    message: '请选择满足类型',
                    trigger: 'blur'
                }],
                resource: [{
                    required: true,
                    message: '请选择活动类型',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '请选择状态',
                    trigger: 'blur'
                }],
                price: [{
                    required: true,
                    message: '请输入条件值',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                discountList: [{
                    required: true,
                    message: '请选择优惠券',
                    trigger: 'blur'
                }],
                resource_id: [{
                    required: true,
                    message: '请输入活动',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
            },
            id: null,
            addVisible: false,
            form: {
                ty: 2,
                state: 2,
                resource: 0,
                discountList: []
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
        }
    },
    created() {

    },
    methods: {
        // 添加优惠券
        addDiscount() {
            this.addVisible = true;
        },
        // 已选优惠券删除
        deleteDiscount(index) {
            this.form.discountList.splice(index, 1)
        },
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        this.loading = true;
                        var url = '/coupon/activity/coupon/add'
                        this.form.ctime = deal.timestamp(this.form.time[0]);
                        this.form.etime = deal.timestamp(this.form.time[1]);
                        let ids = this.form.discountList.map(item =>
                            item.id
                        )
                        this.form.coupon_ids = ids.join(',')
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.loading = false;
                                this.$message.success('新增成功');
                                this.form.discountList = []
                                bus.$emit('close_current_tags', '/activity-GiveDiscount');
                                this.$router.push({ path: '/activity-Withsend' })
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');

                        return false;
                    }
                });
            };
        },
        //获取所有优惠券
        loadDatas() {
            this.loading = true;
            request.get('/coupon/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    let discountList = ret.data.list;
                    let ids = this.form.coupon_ids.split(',')
                    discountList.map(item => {
                        item.ctime = deal.timestamp(item.ctime, 'unix');
                        item.etime = deal.timestamp(item.etime, 'unix');

                        ids.map(items => {
                            if (item.id == items) {
                                this.form.discountList.push(item)
                            }
                        })
                    });
                    console.log(999999, this.form.discountList)

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadData() {
            this.loading = true;
            request.get('/coupon/activity/coupon/detail', { id: this.id }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.form = ret.data.list;
                    this.form.discountList = [];
                    this.loadDatas()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        toggleAdd(val) {
            this.addVisible = val
        },
        getAddGood(val) {
            this.form.discountList = val
        }
    },
}
</script>
<style scoped>
.p-title {
    font-size: 20px;
    font-weight: 900;
}
.dialog_box {
    padding: 30px;
}
.span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box >>> .el-input--small .el-input__inner {
    width: 204%;
}
.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}
.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .el-button {
    margin-left: 20px;
}
</style>